<script lang="ts" setup>
import type { MaybeRefOrGetter } from 'vue';
import ActionButton from '~/components/pro/molecules/ActionButton.vue';
import { useCopyPaste } from '~/composables/shared/useCopyPaste';
interface Props {
  textToCopy: MaybeRefOrGetter<string>;
  successText?: string;
  position?: 'start' | 'end';
  tooltip?: string
    icon?: string;
}

const props = withDefaults(defineProps<Props>(), {
  position: 'start'
});

const { copyToClipboard } = useCopyPaste(() => props.textToCopy);

</script>
<template>
  <ActionButton
    :icon="icon||'i-heroicons-clipboard-document-list'"
    :leading="position === 'start'"
    :trailing="position === 'end'"
    class="text-gray-secondary"
    @click="copyToClipboard"
  >
    <UTooltip v-if="!!tooltip" :text="tooltip!">
      <slot />
    </UTooltip>
    <template v-else>
      <slot />
    </template>
  </ActionButton>
</template>
